import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from '@/locales/init';
import {
  getModelEssayRecordApi,
  saveModelEssayRecordApi,
  IGetModelEssayRecordDto,
  ISaveModelEssayRecordDto,
  checkModelEssayApi,
  checkBigEssaySubjectApi
} from '@/api/writing/modelEssay';
import { paperGuideMetaInfo } from '@/api/writing/thoughts';
import { getPageListByModule } from '@/api/writing/topicList2';
import { getPracticeInfo } from '@/api/writing/practice';

export enum ModelEssayStepEnum {
  SET_TITLE = 0, // 设置题目
  SET_IDEA = 1, // 整理思路
  SET_REQUIRE = 2, // 提出要求
  CREATE = 3 // 生成范文
}

// 默认的写作要求，在自定义题目的时候生效
const default_requirement = i18n.t('common.dauyan_write_modelessay_input_limit');

export interface IModelEssayState {
  /**
   * 加载状态
   */
  loading: boolean;
  model_loading: boolean;
  idea_loading: boolean;
  check_big_essay_subject_loading: boolean;

  /**
   * @description 当前步骤
   */
  current_step: ModelEssayStepEnum;

  /**
   * @description 当前设置的标题，必须要设置，不然不允许进行下一步
   */
  subject: string;

  /**
   * @description 当前记录的 ID
   */
  record_id: number;

  /**
   * @description 当前设置的题目的 ID， 如果是选择的题库中的题目，那么就用这个题目的 ID
   */
  paper_id?: number;

  /**
   * @description 选择题目列表的弹窗状态
   */
  topic_list_modal_status: boolean;

  /**
   * 思路
   */
  thought: string;

  /**
   * 要求
   */
  requirement: string;

  /**
   * 题目来源
   */
  topic_source: 'user' | 'auto' | null;

  /**
   * 使用场景
   */
  topic_scenes: 'record' | 'result';

  /**
   * 是否展示充值的弹窗
   */
  show_pay_modal: boolean;

  /**
   * 是否进行下一步
   */
  show_next_model_essay_idea: number;

  /**
   * 草稿的原始记录
   */
  sample_text_info: any;

  /**
   * 标准题目的轮点论据
   */
  guide_meta: any;

  /**
   * 标准题目的轮点论据的请求状态
   */
  guide_meta_loading: boolean;

  /** 生成范文的内容 */
  content: '';

  /**
   * 是否生成过范文 1 没有生成｜ 2 生成了
   */
  status: 1 | 2;

  /**
   * 检测自定义题目后 ai 给的反馈
   */
  humor_reply: string | undefined;

  /**
   * 题目是否可用
   */
  is_available: 0 | 1 | 2;

  /**
   * ws 的接收状态
   */
  ws_status: boolean;

  /**
   * 是否展示重新加载思路的按钮
   */
  is_show_reload_btn: boolean;

  /**
   * 重新触发获取思路
   */
  trigger_reload: number;

  /**
   * 获取过思路，永远在初始化的时候重新赋值
   */
  got_essay: boolean;

  /**
   * 是否更新了题目
   */
  update_subject: number;

  /**
   * 已经收到了自定义题目的思路
   */
  hasCustomTitleEssay: boolean;

  /**
   * 点击获取思路 或者 点击重新获取
   */
  triggerSend: number;

  /**
   * 链接报错
   */
  ws_link_error: boolean;

  /**
   * 重新连接
   */
  ws_trigger_retry_link: number;

}

const initialState: IModelEssayState = {
  loading: true,
  model_loading: true,
  idea_loading: false,
  check_big_essay_subject_loading: false,
  current_step: ModelEssayStepEnum.SET_TITLE,
  subject: '',
  record_id: 0,
  topic_list_modal_status: false,
  thought: '',
  requirement: '',
  topic_source: null,
  topic_scenes: 'record',
  show_pay_modal: false,
  show_next_model_essay_idea: 0,
  sample_text_info: null,
  paper_id: 0,
  guide_meta: [],
  guide_meta_loading: false,
  content: '',
  status: 1,
  humor_reply: undefined,
  is_available: 0,
  ws_status: false,
  is_show_reload_btn: false,
  trigger_reload: 0,
  got_essay: false,
  update_subject: 0,
  hasCustomTitleEssay: false,
  triggerSend: 0,
  ws_link_error: false,
  ws_trigger_retry_link: 0
};

/**
 * 获取标准题目的论点论据
 */
export const getpaperGuideMetaInfo = createAsyncThunk('modelEssay/paperGuideMetaInfo', async (_, { getState, rejectWithValue }) => {
  const paper_id = (getState() as any).modelEssay.paper_id;
  if (paper_id) {
    const res = await paperGuideMetaInfo(paper_id);
    if (res.code === 200) {
      return res.data.list;
    }
  } else {
    return [];
  }
});


/**
 * 检测是否有权限获取 ai 老师的思路
 */
export const checkModelEssayAuth = createAsyncThunk('modelEssay/checkModelEssayAuth', async (params: any) => {
  console.log('>>>>>>>>>>> checkModelEssayAuth >>>>>>>>>>>>>');
  const res = await checkModelEssayApi(params);
  if (res.code === 200) {
    const is_auth = res.data.is_auth;
    if (is_auth) {
      return { show_pay_modal: false, show_next_model_essay_idea: Date.now() };
    } else {
      return { show_pay_modal: true, show_next_model_essay_idea: 0 };
    }
  }
});

/**
 * 获取范文记录
 */
export const getModelEssayRecordAction = createAsyncThunk('modelEssay/getModalEssayRecord', async (params: IGetModelEssayRecordDto, { getState, rejectWithValue }) => {
  const res = await getModelEssayRecordApi(params);
  if (res.code === 200) {
    let topic_source;
    let response = {} as any;
    response = { ...response, ...res.data, topic_source: topic_source };
    return response;
  } else {
    return rejectWithValue(res);
  }
});


export const saveModelEssayRecordAndGetAction = createAsyncThunk('modelEssay/saveModelEssayRecordAndGetAction', async (params: ISaveModelEssayRecordDto, { getState, rejectWithValue }) => {
  const res = await saveModelEssayRecordApi(params);
  if (res.code === 200) {
    const info = await getModelEssayRecordApi({ id: res.data.id });

    return { ...info.data };
  } else {
    return rejectWithValue(res);
  }
});


/**
 * 创建范文记录
 */
export const saveModelEssayRecordAction = createAsyncThunk('modelEssay/saveModelEssayRecord', async (params: ISaveModelEssayRecordDto, { getState, rejectWithValue }) => {
  const res = await saveModelEssayRecordApi(params);
  if (res.code === 200) {
    return res.data.id;
  } else {
    return rejectWithValue(res);
  }
});

/**
 * 选区题目后获取一下题目详情
 */
export const getPaperInfoBeforeSelectTopic = createAsyncThunk('modelEssay/getPaperInfoBeforeSelectTopic', async (topic: any, { getState, rejectWithValue }) => {
  const info = await getPracticeInfo({ id: topic });
  if (info.code === 200) {
    return info.data.requirement;
  } else {
    return default_requirement;
  }
});

/**
 * 实时保存范文记录
 */
export const saveModelEssayRecordActionRuntime = createAsyncThunk('modelEssay/saveModelEssayRecord', async (params: ISaveModelEssayRecordDto | null, { getState, rejectWithValue }) => {
  const state = getState() as any;
  const payload = params ? params : {
    id: state.modelEssay.record_id,
    paper_id: state.modelEssay.paper_id,
    requirement: state.modelEssay.requirement,
    thought: state.modelEssay.thought,
    subject: state.modelEssay.subject
  };
  const res = await saveModelEssayRecordApi(payload);
  if (res.code === 200) {
    return res.data.id;
  } else {
    return rejectWithValue(res);
  }
});

/**
 * 简册自定义题目的有效性
 */
export const checkBigEssaySubject = createAsyncThunk('modelEssay/checkBigEssaySubject', async (params: any, { }) => {
  console.log('>>>>>>>>>> checkBigEssaySubject >>>>>>>>>>>>>');

  const response = await checkBigEssaySubjectApi(params);
  if (response.code === 200) {
    return response.data;
  } else {
    return {
      is_available: 2,
      humor_reply: i18n.t('common.dauyan_something_wrong')
    };
  }
});

/**
 * 检测输入框的内容是否和库中的题目内容相同
 */
export const checkTitleIsSameAsTopic = createAsyncThunk('modelEssay/checkTitleIsSameAsTopic', async (params: string, { getState, rejectWithValue }) => {
  const res = await getPageListByModule({
    'page': 1,
    'page_size': 10,
    'module_id': 5,
    'year': 0,
    'category': 0,
    'topic': 0,
    'content': params,
    'subject_type': 0,
    'is_accurate': 1
  });
  if (res.code === 200 && res.data.list.length === 1) {
    const topic = res.data.list[0];
    const info = await getPracticeInfo({ id: topic.id });
    return {
      topic: topic,
      requirement: info.data.requirement || ''
    };
  } else {
    return {
      topic: null,
      requirement: default_requirement
    };
  }
});

export const modelEssaySlice = createSlice({
  name: 'modelEssay',
  initialState,
  reducers: {
    triggerRetryLink: (state, action) => {
      state.ws_trigger_retry_link = Date.now();
    },
    updateWSLinkError: (state, action) => {
      state.ws_link_error = action.payload;
    },
    udpateTriggerSend: (state, action) => {
      state.triggerSend = action.payload;
    },
    updateHasCustomTitleEssay: (state, action) => {
      state.hasCustomTitleEssay = action.payload;
    },
    updateUpdateSubject: (state, action) => {
      state.update_subject = action.payload;
    },
    // 触发重新获取
    // 触发重新获取就要恢复一些状态
    updateTriggerReload: (state) => {
      state.trigger_reload = Date.now();
      state.update_subject = 0;
      state.is_show_reload_btn = false;
      state.guide_meta = [];
      state.is_available = 0;
      state.humor_reply = '';
      state.hasCustomTitleEssay = false;
      // state.triggerSend = Date.now();
    },
    updateStateByTitleChnage: (state, action) => {
      state.sample_text_info = null;
      state.paper_id = 0;
      state.subject = action.payload;
      state.update_subject = Date.now();
    },
    updateCurrentStep: (state, action) => {
      state.current_step = action.payload;
    },
    updateCurrentTitle: (state, action) => {
      state.subject = action.payload;
      state.paper_id = 0;
    },
    updateTopicListModalStatus: (state, action) => {
      state.topic_list_modal_status = action.payload;
    },
    updateTopicSource: (state, action) => {
      state.topic_source = action.payload;
    },
    updateTopic: (state, action) => {
      if (action.payload) {
        state.paper_id = action.payload.id;
        state.subject = action.payload.content;
      } else {
        state.paper_id = 0;
        state.subject = '';
      }
      // 重新设置完题目后把状态清空
      state.show_pay_modal = false;
      // 设置完题目后就要有重置按钮
      if (state.got_essay) state.is_show_reload_btn = true;
      state.update_subject = Date.now();
    },
    updateThought: (state, action) => {
      state.thought = action.payload;
    },
    updateRequirement: (state, action) => {
      state.requirement = action.payload;
    },
    updateShowNextModelEssayIdea: (state, action) => {
      state.show_next_model_essay_idea = action.payload;
    },
    updateShowPayModal: (state, action) => {
      state.show_pay_modal = action.payload;
    },
    updateTopicScenes: (state, action) => {
      state.topic_scenes = action.payload;
    },
    updateWSStatus: (state, action) => {
      state.ws_status = action.payload;
    },
    update_check_big_essay_subject_loading: (state, action) => {
      state.check_big_essay_subject_loading = action.payload;
    },
    udpateGotEssay: (state, action) => {
      state.got_essay = action.payload;
    },
    updateIsShowReloadBtn: (state, action) => {
      if (state.got_essay) state.is_show_reload_btn = action.payload;
    },
    resetModelEssay: (state: any) => {
      const keys = Object.keys(initialState);
      keys.forEach(key => {
        state[key] = (initialState as any)[key];
      });
    }
  },
  extraReducers: (builder) => {
    builder
      // 设置草稿
      .addCase(saveModelEssayRecordAction.pending, (state, action) => {
        state.loading = true;
      })
      // 设置草稿
      .addCase(saveModelEssayRecordAction.fulfilled, (state, action) => {
        state.record_id = action.payload;
        state.loading = false;
        state.model_loading = false;
      })
      .addCase(saveModelEssayRecordAndGetAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(saveModelEssayRecordAndGetAction.fulfilled, (state, action) => {
        state.record_id = action.payload.id;
        state.subject = action.payload.subject ? action.payload.subject : action.payload.paper_title;
        state.requirement = action.payload.requirement;
        state.thought = action.payload.thought;
        state.record_id = action.payload.id;
        state.topic_source = action.payload.topic_source;
        state.paper_id = action.payload.paper_id;
        state.sample_text_info = action.payload;
        state.content = action.payload.content;
        state.status = action.payload.status;

        state.humor_reply = action.payload.humor_reply;
        state.is_available = action.payload.is_available;
        state.loading = false;
        state.model_loading = false;
      })
      // 获取草稿
      .addCase(getModelEssayRecordAction.pending, (state, action) => {
        state.loading = true;
        state.model_loading = true;
        state.check_big_essay_subject_loading = true;
      })
      // 获取草稿
      .addCase(getModelEssayRecordAction.fulfilled, (state, action) => {
        state.subject = action.payload.subject ? action.payload.subject : action.payload.paper_title;
        state.requirement = action.payload.requirement;
        state.thought = action.payload.thought;
        state.record_id = action.payload.id;
        state.topic_source = action.payload.topic_source;
        state.paper_id = action.payload.paper_id;
        state.sample_text_info = action.payload;
        state.content = action.payload.content;
        state.status = action.payload.status;
        state.is_available = action.payload.is_available;
        state.humor_reply = action.payload.humor_reply;


        state.loading = false;
        state.model_loading = false;
        state.check_big_essay_subject_loading = false;
        state.is_available = 0;
      })
      // 检测输入的题目是否和库中的相同
      .addCase(checkTitleIsSameAsTopic.pending, (state) => {
        state.idea_loading = true;
      })
      .addCase(checkTitleIsSameAsTopic.fulfilled, (state, action) => {
        if (action.payload && action.payload.topic) {
          state.paper_id = action.payload.topic.id;
          state.topic_source = 'auto';
          if (state.requirement === '') {
            state.requirement = action.payload.requirement;
            state.humor_reply = '';
            state.is_available = 1;
          }
        } else {
          state.paper_id = 0;
          state.topic_source = 'user';
          if (state.requirement === '' && state.subject !== '') {
            state.requirement = default_requirement;
          }
        }
        // 重新设置完题目后把状态清空
        state.show_pay_modal = false;
        state.idea_loading = false;
      })
      // 检测是否有获取思路的权限
      .addCase(checkModelEssayAuth.fulfilled, (state, action) => {
        state.show_pay_modal = !!action.payload?.show_pay_modal;
        state.show_next_model_essay_idea = action.payload?.show_next_model_essay_idea || 0;
      })
      // 请求标准题目下的论点论据
      .addCase(getpaperGuideMetaInfo.pending, (state, action) => {
        state.guide_meta_loading = true;
      })
      // 请求标准题目下的论点论据
      .addCase(getpaperGuideMetaInfo.fulfilled, (state, action) => {
        state.guide_meta = action.payload;
        state.guide_meta_loading = false;
      })
      // 请求 paper info 的接口
      .addCase(getPaperInfoBeforeSelectTopic.fulfilled, (state, action) => {
        if (state.requirement === '') {
          state.requirement = action.payload;
        }
      })
      // 请求校验自定义题目的接口
      .addCase(checkBigEssaySubject.pending, (state, action) => {
        state.check_big_essay_subject_loading = true;
      })
      .addCase(checkBigEssaySubject.fulfilled, (state, action) => {
        state.humor_reply = action.payload.humor_reply;
        state.is_available = action.payload.is_available;
        state.check_big_essay_subject_loading = false;
      });
  }
});

export default modelEssaySlice;
export const modelEssayActions = modelEssaySlice.actions;